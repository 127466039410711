@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
$theme-colors: (
	'site-primary': #30d0ef,
	'site-pink': #e425b8,
);
$body-color: #050505;
$spacer: 1rem !default;
$spacers: (
	0: 0,
	1: (
		$spacer * 0.25,
	),
	//4px
	2:
		(
			$spacer * 0.5,
		),
	//8px
	3: $spacer,
	//16px
	4:
		(
			$spacer * 1.5,
		),
	//24px
	5:
		(
			$spacer * 3,
		),
	//48px
	6:
		(
			$spacer * 4,
		),
	//64px
	7:
		(
			$spacer * 5,
		),
	//80px
	8:
		(
			$spacer * 6.25,
		),
	//100px
	9:
		(
			$spacer * 7.5,
		),
	//120px
	10:
		(
			$spacer * 9.375,
		),
	//150px
	-1: (
			$spacer * -0.25,
		),
	//4px
	-2: (
			$spacer * -0.5,
		),
	//8px
	-3: -$spacer,
	//16px
	-4: (
			$spacer * -1.5,
		),
	//24px
	-5: (
			$spacer * -3,
		),
	//48px
	-6: (
			$spacer * -4,
		),
	//64px
	-7: (
			$spacer * -5,
		),
	//80px
	-8: (
			$spacer * -6.25,
		),
	//100px
	-9: (
			$spacer * -7.5,
		),
	//120px
	-10: (
			$spacer * -9.375,
		) //150px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);
@import 'node_modules/bootstrap/scss/bootstrap';
/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: linear-gradient(
		81.57deg,
		rgba(228, 37, 184, 0.8) -7.26%,
		rgba(0, 255, 255, 0.8) 111.48%
	);
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(
		81.57deg,
		rgba(228, 37, 184, 0.8) -7.26%,
		rgba(0, 255, 255, 0.8) 111.48%
	);
}
html {
	scroll-behavior: smooth;
}
body {
	background-color: $body-color;
	font-family: 'Poppins';
}
.pointer {
	cursor: pointer;
}
*:focus {
	border-color: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.label {
	font-weight: 400;
	font-size: 19.1915px;
	line-height: 29px;
	color: #ffffff;
	@media (max-width: 560px) {
		font-size: 14.1915px;
	}
}
.balance {
	font-weight: 500;
	font-size: 19.1915px;
	line-height: 29px;
	color: #ffffff;
	@media (max-width: 560px) {
		font-size: 14.1915px;
	}
}
.heading {
	font-weight: 600;
	font-size: 60px;
	line-height: 90px;
	color: #ffffff;
	@media (max-width: 767.98px) {
		margin-top: 150px;
		font-size: 50px;
		line-height: 70px;
	}
	@media (max-width: 567.98px) {
		margin-top: 150px;
		font-size: 40px;
		line-height: 60px;
	}
	@media (max-width: 567.98px) {
		margin-top: 150px;
		font-size: 35px;
		line-height: 50px;
	}
}
.card {
	background: radial-gradient(
		97.91% 97.91% at 50% 50%,
		rgba(217, 217, 217, 0) 0%,
		rgba(1, 255, 254, 0.21) 100%
	) !important;
	padding: 72px 129px 50px 129px !important;
	@media (max-width: 767.98px) {
		padding: 40px 30px 25px 30px !important;
	}
	&__title {
		font-weight: 600;
		font-size: 40px;
		line-height: 60px;
		color: #ffffff;
		@media (max-width: 767.98px) {
			font-size: 30px;
			line-height: 40px;
		}
	}
	&__desc {
		font-weight: 400;
		font-size: 24px;
		line-height: 45px;
		color: #ffffff;
		@media (max-width: 767.98px) {
			font-size: 20px;
			line-height: 25px;
		}
	}
}
.heading-card {
	background: linear-gradient(
		81.57deg,
		rgba(1, 255, 254, 0.2) -7.26%,
		rgba(0, 255, 255, 0.2) 111.48%
	);
	padding: 40px 50px !important;
	position: relative;
	height: 100%;
	&__title {
		font-weight: 600;
		font-size: 29px;
		line-height: 143%;
		color: #ffffff;
		margin-bottom: 80px;
	}
	&__read {
		position: absolute;
		bottom: 0;
		margin-bottom: 20px;
		font-weight: 600;
		font-size: 20.6391px;
		line-height: 35px;
		color: #ffffff;
		cursor: pointer;
	}
}
.heading-card:hover {
	background: linear-gradient(
		81.57deg,
		rgba(228, 37, 184, 0.4) -7.26%,
		rgba(0, 255, 255, 0.4) 111.48%
	);
}
.heading-card:hover span {
	color: #00ffff !important;
}
.heading-card__active {
	padding: 40px 50px !important;
	background: linear-gradient(
		81.57deg,
		rgba(228, 37, 184, 0.4) -7.26%,
		rgba(0, 255, 255, 0.4) 111.48%
	);
	h2 {
		font-weight: 600;
		font-size: 62.7832px;
		line-height: 120%;
		color: #01fffe;
		@media (max-width: 767.98px) {
			font-size: 22.6391px;
			line-height: 35px;
		}
	}
	p {
		color: white !important;
		font-weight: 400;
		font-size: 23.6391px;
		line-height: 35px;
		@media (max-width: 767.98px) {
			font-size: 16.6391px;
			line-height: 25px;
		}
	}
}
.tokenomics-card {
	background: rgba(1, 255, 254, 0.13);
	box-shadow: 0px 10.9946px 30.9223px rgba(0, 0, 0, 0.35);
	border-radius: 0px;
	padding: 23px 14px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	h2 {
		font-weight: 600;
		font-size: 30px;
		line-height: 45px;
		color: #ffffff;
	}
}
.contract__address {
	background: rgba(1, 255, 254, 0.13);
	box-shadow: 0px 10.9946px 30.9223px rgba(0, 0, 0, 0.35);
	border-radius: 0px;
	padding: 10px;
	border: 2px solid #e425b8;
	position: relative;
	p {
		font-weight: 500;
		font-size: 19.1915px;
		line-height: 29px;
		color: #ffffff;
		word-wrap: break-word;
		@media (max-width: 767.98px) {
			font-size: 14.1915px;
		}
	}
}
.collapse-card {
	background: rgba(1, 255, 254, 0.3);
	box-shadow: 0px 10.9946px 30.9223px rgba(0, 0, 0, 0.35);
	border-radius: 0px;
	padding: 40px 64px !important;
	h2 {
		font-weight: 600;
		font-size: 30px;
		line-height: 93%;
		color: #ffffff;
		@media (max-width: 767.98px) {
			font-size: 22px;
			line-height: 43px;
		}
		@media (max-width: 467.98px) {
			font-size: 20px;
			line-height: 33px;
		}
	}
	p {
		font-weight: 400;
		font-size: 24px;
		line-height: 45px;
		color: #ffffff;
		@media (max-width: 767.98px) {
			font-size: 16px;
			line-height: 23px;
		}
	}
	@media (max-width: 767.98px) {
		padding: 25px 20px !important;
	}
}
.colored-text {
	background: linear-gradient(81.57deg, #e425b8 -7.26%, #00ffff 111.48%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
.navbar-toggler {
	color: white !important;
}
.btn__primary {
	padding: 18px 24px !important;
	background: linear-gradient(
		81.57deg,
		#e425b8 -7.26%,
		#00ffff 111.48%
	) !important;
	color: white !important;
	border-radius: 0 !important;
	border: 0 !important;
	font-size: 18px !important;
	font-weight: 600 !important;
	@media (max-width: 560px) {
		font-size: 16px !important;
		padding: 12px 24px !important;
	}
	&-header {
		@extend .btn__primary;
		@media (max-width: 767.98px) {
			width: 100%;
		}
	}
}
.btn__primary-sm {
	padding: 6px 16px !important;
	background: linear-gradient(
		81.57deg,
		#e425b8 -7.26%,
		#00ffff 111.48%
	) !important;
	color: white !important;
	border-radius: 0 !important;
	border: 0 !important;
	font-size: 14px !important;
	font-weight: 600 !important;
}
.btn-top {
	padding: 6px 10px !important;
	background: linear-gradient(
		81.57deg,
		#e425b8 -7.26%,
		#00ffff 111.48%
	) !important;
	color: white !important;
	border-radius: 0 !important;
	border: 0 !important;
	position: fixed;
	bottom: 0;
	margin-bottom: 15px;
	right: 0;
	margin-right: 15px;
	z-index: 10000 !important;
}
.btn__secondary {
	background: #ffffff !important;
	border-radius: 6.64867px !important;
	color: black !important;
	padding: 15px 22px !important;
	font-weight: 500 !important;
	font-size: 18.2485px !important;
	line-height: 27px !important;
	@media (max-width: 767.98px) {
		padding: 10px 22px !important;
		font-weight: 500 !important;
		font-size: 15.2485px !important;
		line-height: 20px !important;
	}
}
.dashboard {
	min-height: 100vh;
	&__presale {
		&__card {
			margin: 0 auto;
			max-width: 696.87px !important;
			padding: 40px 48px !important;
			background: rgba(1, 255, 254, 0.13);
			box-shadow: 0px 15.3532px 43.1808px rgba(0, 0, 0, 0.35);
			@media (max-width: 560px) {
				padding: 20px 28px !important;
			}
			&__form-control {
				background: rgba(1, 255, 254, 0.14) !important;
				border: 0 !important;
				padding: 30px 70px 30px 20px !important;
				font-weight: 500;
				font-size: 26.7872px;
				line-height: 43px;
				letter-spacing: -0.045em;
				color: #ffffff !important;
				opacity: 0.5;
				@media (max-width: 560px) {
					font-size: 20.7872px;
					line-height: 23px;
					padding: 20px 70px 20px 10px !important;
				}
			}
			&__addon {
				position: absolute;
				top: 0;
				right: 0;
				margin-top: 12px;
				margin-right: 20px;
				font-weight: 500;
				font-size: 20.0298px;
				line-height: 35px;
				letter-spacing: -0.045em;
				color: #ffffff;
				@media (max-width: 560px) {
					font-size: 17.7872px;
					line-height: 23px;
					margin-top: 11px;
				}
			}
		}
	}
	&__staking {
		&__card {
			// margin: 0 auto;
			// max-width: 696.87px !important;
			height: 100%;
			padding: 40px 48px;
			background: rgba(1, 255, 254, 0.13);
			box-shadow: 0px 15.3532px 43.1808px rgba(0, 0, 0, 0.35);
			@media (max-width: 560px) {
				padding: 20px 28px !important;
			}
			&-title {
				font-weight: 500;
				font-size: 20.7669px;
				line-height: 29px;
				color: #ffffff;
				@media (max-width: 560px) {
					font-size: 16.7669px;
				}
			}
			&-subtitle {
				font-weight: 400;
				font-size: 16.2699px;
				line-height: 29px;
				letter-spacing: -0.045em;
				color: #ffffff;
				@media (max-width: 560px) {
					font-size: 14.7669px;
				}
			}
			&__desc {
				font-weight: 400;
				font-size: 14.0028px;
				line-height: 21px;
				letter-spacing: -0.045em;
				color: #ffffff;
				opacity: 0.5;
				@media (max-width: 560px) {
					font-size: 12.7669px;
				}
			}
			&__number {
				font-weight: 500;
				font-size: 66.9426px;
				line-height: 55px;
				letter-spacing: -0.045em;
				color: #ffffff;
				@media (max-width: 560px) {
					font-size: 46.7669px;
				}
			}
			&__form-control {
				background: rgba(1, 255, 254, 0.14) !important;
				border: 0 !important;
				padding: 30px 70px 30px 20px !important;
				font-weight: 500;
				font-size: 26.7872px;
				line-height: 43px;
				letter-spacing: -0.045em;
				color: #ffffff !important;
				opacity: 0.5;
				@media (max-width: 560px) {
					font-size: 20.7872px;
					line-height: 23px;
					padding: 20px 70px 20px 10px !important;
				}
			}
			&__addon {
				position: absolute;
				top: 0;
				right: 0;
				margin-top: 12px;
				margin-right: 20px;
				font-weight: 500;
				font-size: 20.0298px;
				line-height: 35px;
				letter-spacing: -0.045em;
				color: #ffffff;
				@media (max-width: 560px) {
					font-size: 17.7872px;
					line-height: 23px;
					margin-top: 11px;
				}
			}
		}
	}
}
.hero {
	&__logo-elipse {
		position: absolute;
		top: 0;
		left: 0;
		// margin-top: -250px;
		z-index: -1;
	}
	&__navbar {
		padding: 30px 80px !important;
		z-index: 10000;
		@media (max-width: 620.98px) {
			padding: 30px 25px !important;
		}
		.nav-link {
			color: #ffff !important;
			font-weight: 500 !important;
			font-size: 18px !important;
			line-height: 27px !important;
			margin-right: 40px;
			cursor: pointer;
		}
	}
	&__about {
		&-title {
			font-weight: 700;
			font-size: 270.297px;
			line-height: 330px;
			color: #ffffff;
			z-index: 1000;
			@media (max-width: 1199.98px) {
				font-size: 200px;
			}
			@media (max-width: 991.98px) {
				font-size: 150px;
				line-height: 230px;
			}
			@media (max-width: 767.98px) {
				font-size: 90px;
				line-height: 130px;
			}
			@media (max-width: 467.98px) {
				font-size: 65px;
			}
		}
		&__form-container {
			z-index: 1000;
			width: 60%;
			@media (max-width: 767.98px) {
				width: 100%;
			}
			&-form-control {
				padding: 35px 160px 35px 20px !important;
				background: rgba(255, 255, 255, 0.1) !important;
				// border: 0.664867px solid #ffffff !important;
				border: 0 !important;
				border-radius: 6.64867px !important;
				font-weight: 300;
				font-size: 23.2703px;
				line-height: 35px;
				letter-spacing: -0.06em;
				color: #ffffff !important;
				@media (max-width: 767.98px) {
					padding: 25px 160px 25px 20px !important;
					font-size: 15.2703px;
				}
			}
			&-form-control::placeholder {
				font-weight: 300;
				font-size: 23.2703px;
				line-height: 35px;
				letter-spacing: -0.06em;
				color: #ffffff;
				@media (max-width: 767.98px) {
					font-size: 15.2703px;
				}
			}
			&-btn {
				position: absolute;
				top: 0;
				right: 0;
				margin-right: 10px;
				margin-top: 6.5px;
				@media (max-width: 767.98px) {
					margin-top: 4.5px;
				}
			}
		}
		&__heading {
			font-weight: 400;
			font-size: 50.834px;
			line-height: 76px;
			color: white !important;
			@media (max-width: 767.98px) {
				font-size: 30.834px;
				line-height: 46px;
			}
		}
		&__desc {
			font-weight: 400;
			font-size: 24px;
			line-height: 45px;
			color: #ffffff;
			@media (max-width: 767.98px) {
				font-size: 20.834px;
				line-height: 26px;
			}
		}
	}
}
.carbon_ecosystem {
	&-title {
		margin-top: 250px;
		font-weight: 600;
		font-size: 60px;
		line-height: 90px;
		color: #ffffff;
		@media (max-width: 767.98px) {
			margin-top: 150px;
			font-size: 50px;
			line-height: 40px;
		}
	}
	&-subheading {
		font-weight: 600;
		font-size: 40px;
		line-height: 60px;
		color: #ffffff;
		@media (max-width: 767.98px) {
			font-size: 30px;
			line-height: 30px;
		}
	}
	&-desc {
		font-weight: 400;
		font-size: 24px;
		line-height: 45px;
		color: #ffffff;
		@media (max-width: 767.98px) {
			font-size: 20px;
			line-height: 26px;
		}
	}
	&__card {
		background: radial-gradient(
			97.91% 97.91% at 50% 50%,
			rgba(217, 217, 217, 0) 0%,
			rgba(1, 255, 254, 0.21) 100%
		) !important;
		height: 100%;
		padding: 54px 30px 30px 30px !important;
		&-desc {
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: #ffffff;
		}
	}
}
.hero-green {
	position: absolute;
	z-index: -1;
	opacity: 0.7;
	width: 100%;
	margin: -90px auto 0 auto !important;
}
.elipse5 {
	position: absolute;
	top: 0;
	opacity: 0.5;
	z-index: -1;
}
.elipse4 {
	position: absolute;
	top: 0;
	left: 0;
	margin-top: -60%;
	opacity: 0.52;
	z-index: -1;
}
.elipse6 {
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -50%;
	opacity: 0.6;
	z-index: -1;
}
.elipse7 {
	position: absolute;
	top: 0;
	margin-top: -70%;
	opacity: 0.6;
	z-index: -1;
}
.elipse10 {
	z-index: -1;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -1050px;
	@media (max-width: 767.98px) {
		margin-top: -600px;
	}
	@media (max-width: 467.98px) {
		margin-top: -400px;
	}
	@media (max-width: 367.98px) {
		margin-top: -300px;
	}
}
.elipse16 {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	margin-top: -600px;
	@media (max-width: 767.98px) {
		margin-top: -300px;
	}
	@media (max-width: 467.98px) {
		margin-top: 50px;
	}
}
.elipse17 {
	z-index: -1;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -250px;
	@media (max-width: 1023.98px) {
		margin-top: -50px;
	}
	@media (max-width: 767.98px) {
		margin-top: 50px;
	}
	@media (max-width: 467.98px) {
		margin-top: 150px;
	}
}
.elipse18 {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
}
.elipse19 {
	z-index: -1;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -200px;
}
.elipse22 {
	position: absolute;
	top: 0;
	opacity: 0.7;
	z-index: -1;
	margin-top: -500px;
}
.top-elipse {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.52;
	max-height: 100%;
	z-index: -1;
}
.bottom-elipse {
	position: absolute;
	bottom: 0;
	right: 0;
	opacity: 0.52;
	z-index: -1;
}
.bg-frame {
	position: absolute;
	top: 0;
	left: 0;
	margin-top: -50px;
	width: 100%;
	z-index: -1 !important;
	opacity: 0.5;
}
.globe {
	position: absolute;
	width: 100%;
	top: 0;
	margin-top: -250px;
	opacity: 0.8;
	@media (min-width: 2140px) {
		margin-top: -550px;
	}
	@media (max-width: 1024.98px) {
		margin-top: 70px;
	}
	@media (max-width: 767.98px) {
		margin-top: 270px;
	}
	@media (max-width: 467.98px) {
		margin-top: 470px;
	}
}
